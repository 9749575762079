import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Col, Row, Container } from "reactstrap";
import { useLocation } from "react-router-dom";

import { CaseDetail } from "components/CaseDetail";

import { GetTrademarks, dashboardEarnings, getDashboardTrademark } from "api/api.service";
import { Loader } from "components/Loader";
import { METATAGS } from "../../../constants";
import TrademarkCard from "components/customer/trademark/TrademarkCard";
import { TextInput } from "components/Input";
import PasswordLockIcon from "assets/images/customer/svg/PasswordLockIcon";
import FilterIcon from "assets/images/customer/svg/FilterIcon";
import { CustomButton } from "components/CustomButton";
import SearchIcon from "assets/images/customer/svg/SearchIcon";
import { CUSTOMER_ROUTES } from "helpers/routeHelpers";
import { useDispatch, connect, useSelector } from "react-redux";
import TrademarkFilterModal from "components/customer/trademark/TrademarkFilterModal";
import Logo from "assets/images/customer/svg/Logo";
import LoaderIcon from "assets/images/customer/svg/LoaderIcon";
import { CustomModal } from "components/CustomModal";
import shortLogo from "assets/images/customer/short-logo.png";
import { withRouter } from "react-router-dom";
import { SIGN_OUT } from "store/actions";
import { useFormik } from "formik";
import { clearFields } from "redux-form";
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest";
import { Pagination } from "../../../components/Pagination";
import { SearchBox } from "../../../components/SearchBox";
import { set } from "lodash";

const Trademark = (props) => {
    const location = useLocation();
    const { user } = useSelector((state) => state?.userAuth);
    const [splasher, setsplasher] = useState(false);
    const [filters, setFilters] = useState({
        filterType: "",
        statusValue: [],
        filterLabel: ""
    });

    const [isFilterModal, setisFilterModal] = useState(false);

    const {
        page,
        handlePageClick,
        pageSize,
        onChangePageSize,
        searchText,
        handleSearchChange,
        updatePageOnFilterChange,
        data,
        total,
        request,
        isFetching
    } = useLocalPaginatedRequest({
        requestFn: GetTrademarks,
        params: {
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumns: filters?.filterType }),
            ...(filters?.filterType &&
                filters?.statusValue && { filterColumnsValue: filters?.statusValue })
        },
        deps: [filters.statusValue]
    });

    useEffect(() => {
        if (location.state?.refresh) {
            request();
        }
    }, [location.state]);

    const handleFilter = async (val) => {
        setTimeout(() => {
            if (filters) {
                updatePageOnFilterChange();
            }
        }, 1200);

        setisFilterModal(false);
    };

    const showAddTrademarkLink = () => {
        if (isFetching) return false; // Always hide link if fetching

        // 1. Show link when data is available (with or without search or filters)
        if (data.length > 0) return true;

        // 2. Show link when not searching, not filtering, and no data (i.e., initial state with no trademarks)
        if (searchText.length === 0 && filters?.filterType === "" && data.length === 0) return true;

        // 3. Hide link when searching or filtering and no data is available
        if ((searchText.length > 0 || filters?.filterType) && data.length === 0) return false;

        return false;
    };

    return (
        <React.Fragment>
            {splasher ? (
                <div style={{ backgroundColor: "#704DE7", width: "100%" }} className="splasher">
                    <Logo style={{ width: "39.366rem" }} />
                    <div className="loader mt-4 ">
                        <LoaderIcon />
                    </div>
                </div>
            ) : (
                <div className="p-3 trademark">
                    <MetaTags>
                        <title>{METATAGS.TRADEMARKS}</title>
                    </MetaTags>
                    <Container fluid>
                        <Row className="greetings">
                            <h2>Greetings,</h2>
                            <h1 className="text-black">{user?.name || ""}</h1>
                            <hr />
                        </Row>
                        {data.length != 0 || searchText.length > 0 || filters?.filterLabel ? (
                            <Row>
                                <Col className="mx-2">
                                    <div className="tradmark-search-form my-2 ">
                                        <SearchBox
                                            iconClass="main-header-search-icon"
                                            className="main-header-search-box main-header-search-box-modified"
                                            placeholder="Search.."
                                            onChange={handleSearchChange}
                                            value={searchText}
                                        />

                                        <button
                                            type="button"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setisFilterModal(true);
                                            }}
                                            className="filter-btn"
                                        >
                                            <FilterIcon />
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        ) : null}
                    </Container>
                    <Container fluid>
                        <div className="mx-2 mb-1  d-flex align-items-center">
                            {filters?.filterLabel ? (
                                <div className="filterLabel">
                                    <span>{filters?.filterLabel}</span>
                                    <button
                                        className="reset-btn"
                                        onClick={() => {
                                            setFilters({
                                                filterType: "",
                                                statusValue: [],
                                                filterLabel: ""
                                            });
                                        }}
                                    >
                                        x
                                    </button>
                                </div>
                            ) : null}
                        </div>

                        {data.length > 0 && !isFetching ? (
                            <>
                                <Row className="justify-content-between flex-wrap">
                                    {data &&
                                        data.map((item, idx) => {
                                            return (
                                                <Col md={6} sm={6} key={idx}>
                                                    <TrademarkCard data={item} />
                                                </Col>
                                            );
                                        })}
                                </Row>
                                <Pagination
                                    currentPage={page}
                                    totalData={total}
                                    onPageClick={handlePageClick}
                                    className="pagination-contain "
                                    rowLimit={pageSize}
                                />
                            </>
                        ) : data.length === 0 &&
                          searchText.length === 0 &&
                          !isFetching &&
                          !filters?.filterLabel ? (
                            <div className="my-4 d-flex flex-column justify-content-center ">
                                <h1 className="bold" style={{ fontSize: "40px" }}>
                                    INTRODUCTION
                                </h1>
                                <p style={{ fontSize: "20px" }}>
                                    Welcome! You have reached <img src={shortLogo} alt="logo" /> Web
                                    Platform for International Trademark Registration{" "}
                                    <img src={shortLogo} alt="logo" /> enables you to register your
                                    trademark in any country in the world through your smartphone or
                                    web platform. Registration of your trademark with a{" "}
                                    <img src={shortLogo} alt="logo" /> Web Platform will simply take
                                    5 steps. Step 1 is for the Submission of your request. Only
                                    after the payment of step 1, you will receive a case number.
                                    This case number can be used for calling over the phone and
                                    chatting over the chat box with our experts with respect only to
                                    the progress of your application. The other Steps 2, 3, 4, and 5
                                    steps relate to your Trademark's filing, publication,
                                    registration, and certificate.
                                </p>
                            </div>
                        ) : data.length === 0 && !isFetching ? (
                            <div className="my-5 d-flex justify-content-center align-items-center">
                                <h4>No Trademark Found</h4>
                            </div>
                        ) : (
                            <Loader classes="vh-50" showVerifyText={false} />
                        )}
                        {/* {searchText.length < 0 || total ===0||
                            data.length === 0 ||
                            (!isFetching && (
                                <Link
                                    to={CUSTOMER_ROUTES.ADD_TRADEMARK}
                                    style={{
                                        fontWeight: "600",
                                        backgroundColor: "#704de7",
                                        color: "#fff",
                                        padding: "0.67rem 0.75rem",
                                        fontSize: "1rem"
                                    }}
                                    className="px-1 mt-3 d-block rounded text-center"
                                >
                                    Add New Trademark
                                </Link>
                            ))} */}
                        {/* {((searchText.length === 0 && total === 0 && data.length === 0) ||
                            (!filters?.filterLabel && data.length > 0)) &&
                            !isFetching && ( */}
                        {showAddTrademarkLink() && (
                            <Link
                                to={CUSTOMER_ROUTES.ADD_TRADEMARK}
                                style={{
                                    fontWeight: "600",
                                    backgroundColor: "#704de7",
                                    color: "#fff",
                                    padding: "0.67rem 0.75rem",
                                    fontSize: "1rem"
                                }}
                                className="px-1 mt-3 d-block rounded text-center"
                            >
                                Add New Trademark
                            </Link>
                        )}
                    </Container>
                </div>
            )}

            <TrademarkFilterModal
                ismodalOpen={isFilterModal}
                handleClose={setisFilterModal}
                handleApplyFilter={handleFilter}
                handleSetFilter={setFilters}
            />
            {/* {isFristTime && (
                <CustomModal
                    isOpen={ismodalOpen}
                    size="xl"
                    className={"splasher"}
                    data={
                        <div className="">
                            <div className=" text-center">
                                <Logo />
                            </div>
                            <p>
                                Welcome! You have reached <img src={shortLogo} alt="logo" /> Web
                                Platform for International Trademark Registration{" "}
                                <img src={shortLogo} alt="logo" /> enables you to register your
                                trademark in any country in the world through your smartphone.
                                Registration of your trademark with a{" "}
                                <img src={shortLogo} alt="logo" /> Web Platform will simply take 5
                                steps. Step 1 is for the Submission of your request. Only after the
                                payment of step 1, you will receive a case number. This case number
                                can be used for calling over the phone and chatting over the chat
                                box with our experts with respect only to the progress of your
                                application. The other Steps 2, 3, 4, and 5 steps relate to your
                                Trademark's filing, publication, registration, and certificate.
                            </p>

                            <div className="d-flex align-items-center justify-content-center">
                                <CustomButton
                                    outline={true}
                                    title="Exit"
                                    className="splasher-btn  mr-4 login-btn-typo"
                                    onClick={() => {
                                        handleSignout();
                                    }}
                                />
                                <CustomButton
                                    outline={true}
                                    title="Continue"
                                    className="splasher-btn  login-btn-typo"
                                    onClick={() => {
                                        setIsFristTime(false);
                                        localStorage.setItem("ConsentCheck", true);
                                        setIsmodalOpen(false);
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            )} */}
        </React.Fragment>
    );
};
const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch({ type: SIGN_OUT })
});

export default connect(null, mapDispatchToProps)(withRouter(Trademark));
