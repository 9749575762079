import React, { useState } from "react";
import MetaTags from "react-meta-tags";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import AuthWrapper from "./AuthWrapper";
import { TextInput } from "../../../components/Input";
import { CustomButton } from "../../../components/CustomButton";
import { CUSTOMER_ROUTES } from "../../../helpers/routeHelpers";
import toast from "react-hot-toast";

// action
import { METATAGS } from "../../../constants";

import { Link } from "react-router-dom";
import PhoneInput, {
    isValidPhoneNumber,
    parsePhoneNumber,
    getCountryCallingCode
} from "react-phone-number-input";
// import images
import loginImage from "../../../assets/images/aiAPaiT/login/login-img.png";
import Logo from "../../../assets/images/customer/svg/Logo";
import PasswordLockIcon from "../../../assets/images/customer/svg/PasswordLockIcon";
import passIcon from "../../../assets/images/aiAPaiT/lock-icon.png";
import showPassIcon from "../../../assets/images/aiAPaiT/show-lock-icon.svg";
import UserProfileIcon from "assets/images/customer/svg/UserProfileIcon";
import EmailIcon from "assets/images/customer/svg/EmailIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SignUp } from "./../../../api/api.service";
import { useDispatch } from "react-redux";
import { SET_TOKEN } from "store/actions";

const CustomerRegister = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [holdToken, setHoldToken] = useState("");

    const [passwordFieldType, setPasswordFieldType] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const passwordFieldChangeHandler = () => {
        setPasswordFieldType(!passwordFieldType);
    };

    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            phoneNum: "",
            name: "",
            countryCodeAlpha: "",
            countryCode: "",
            phone: "",
            email: "",
            password: "",
            userType: "USER",
            providerType: "GOOGLE"
        },

        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Username"),
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string()
                .required("Please Enter Your New Password")
                .min(8, "Password should be minimum 8 characters long")
                .max(25, "Password should be between 8 to 25 characters"),
            phoneNum: Yup.string()
                .required("Enter Phone Number")
                .test("phoneNum", "Phone number is invalid", function (val) {
                    return val && isValidPhoneNumber(val);
                })
        }),

        onSubmit: (initialValues) => {
            handleSubmit(initialValues);
        }
    });

    const handleSubmit = async (initialValues) => {
        // history.push(CUSTOMER_ROUTES.LOGIN)
        const { phoneNum } = initialValues;

        let countryCodeAlpha = parsePhoneNumber(phoneNum).country;
        let countryCode = getCountryCallingCode(countryCodeAlpha);
        countryCode = `+${countryCode}`;
        let phone = phoneNum.split(countryCode)[1];

        let formValues = { ...initialValues };

        formValues.phone = phone;
        formValues.countryCodeAlpha = countryCodeAlpha;
        formValues.countryCode = countryCode;

        delete formValues.phoneNum;

        let userPhone = {
            countryCode: countryCode,
            phone: phone
        };

        try {
            setIsLoading(true);
            await SignUp({
                ...formValues
            })
                .then(async (res) => {
                    dispatch({ type: SET_TOKEN, token: res.token });
                    // dispatch(getMe(res.Token))
                    setHoldToken(res?.token);
                    // console.log(holdToken, "holdToken");
                    // console.log(res?.token, "res?.token");
                    toast.success("OTP sent successfully !");

                    // setTimeout(() => {
                    //     console.log("hello");
                    history.push({
                        pathname: CUSTOMER_ROUTES.REGISTER_VERIFY,
                        state: { tokenId: res?.token, phoneData: userPhone }
                    });
                    // }, 1200);

                    return Promise.resolve({});
                })
                .catch((err) => {
                    // return Promise.reject(err?.message);
                });
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>{METATAGS.REGISTER}</title>
            </MetaTags>
            <AuthWrapper
                leftContent={
                    <div style={{ backgroundColor: "#704DE7" }} className="customer-login ">
                        <Logo />
                    </div>
                }
                data={
                    <div className="customer-login-form">
                        <div className="title">
                            <h1 className="">Create Your Account</h1>
                            <p className="mb-4 ms-0 " style={{}}>
                                Let's get started
                            </p>
                        </div>

                        <form className="" onSubmit={formik.handleSubmit}>
                            <div className="mb-4">
                                <TextInput
                                    preAppendIconFile={<UserProfileIcon />}
                                    placeholder="Full Name"
                                    name={"name"}
                                    type="text"
                                    // className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.username &&
                                        formik.errors.username &&
                                        "border-danger"
                                    }`}
                                    onChange={formik.handleChange}
                                    value={formik?.values?.name}
                                    onBlur={formik.handleBlur}
                                    autoFocus
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <span className="error">{formik.errors.name}</span>
                                ) : null}
                            </div>
                            <div className="mb-4">
                                <TextInput
                                    placeholder="Email Address"
                                    preAppendIconFile={<EmailIcon />}
                                    name={"email"}
                                    type="email"
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.email &&
                                        formik.errors.email &&
                                        "border-danger"
                                    }`}
                                    onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    autoFocus
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error">{formik.errors.email}</span>
                                ) : null}
                            </div>
                            <div className="mb-4">
                                <PhoneInput
                                    defaultCountry={"SA"}
                                    countryOptionsOrder={["SA", "AE", "OM", "BH", "KW", "QA"]}
                                    onChange={(values) => formik.setFieldValue("phoneNum", values)}
                                    onBlur={() => {
                                        formik.setFieldTouched("phoneNum");
                                    }}
                                    value={formik.values.phoneNum}
                                    name="phoneNum"
                                    placeholder="Phone Number"
                                    className="phone-num-con"
                                />
                                {formik.touched.phoneNum && formik.errors.phoneNum ? (
                                    <span className="error">{formik.errors.phoneNum}</span>
                                ) : null}
                            </div>

                            <div className="mb-4">
                                <TextInput
                                    preAppendIconFile={<PasswordLockIcon />}
                                    placeholder="Password"
                                    type={!passwordFieldType ? "password" : "text"}
                                    className="hide-icon"
                                    inputGroupClassName={`${
                                        formik.touched.password &&
                                        formik.errors.password &&
                                        "border-danger"
                                    }`}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    iconFile={!passwordFieldType ? passIcon : showPassIcon}
                                    appendIcon={true}
                                    iconClickHandler={passwordFieldChangeHandler}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <span className="error">{formik.errors.password}</span>
                                ) : null}
                            </div>

                            <CustomButton
                                loading={isLoading}
                                type="submit"
                                color="primary"
                                title="Register Now"
                                className="w-100 login-btn-typo"
                            />
                            <div
                                className="d-flex align-items-center justify-content-center py-3"
                                style={{ fontSize: "1rem", color: "#8566E7" }}
                            >
                                <p style={{ fontWeight: "600" }}>Already have an account? </p>
                                <Link
                                    to={CUSTOMER_ROUTES.LOGIN}
                                    style={{ fontWeight: "600" }}
                                    className="px-1"
                                >
                                    Login
                                </Link>
                            </div>
                        </form>
                    </div>
                }
                leftImgUrl={loginImage}
            />
        </React.Fragment>
    );
};

export default CustomerRegister;
